@font-face {
  font-family: 'Knockout-HTF29-JuniorLiteweight';
  src: url('./fonts/Knockout-HTF29-JuniorLiteweight.otf') format('opentype');
}

@font-face {
  font-family: 'Knockout-HTF70-FullWelterwt';
  src: url('./fonts/Knockout-HTF70-FullWelterwt.otf') format('opentype');
}

@font-face {
  font-family: 'GillSansBold';
  src: url('./fonts/GillSansBold.woff') format('woff');
}

@font-face {
  font-family: 'GillSansBolder';
  src: url('./fonts/GillSansBolder.woff2') format('woff2');
}

@font-face {
  font-family: 'DomaineTextTest-Regular';
  src: url('./fonts/DomaineTextTest-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Apercu-Mono';
  src: url('./fonts/Apercu-Mono.otf') format('opentype');
}

@font-face {
  font-family: 'Montserrat-Light';
  src: url('fonts/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-ExtraLight';
  src: url('fonts/Montserrat-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'akzidenz-grotesk-condensed';
  src: url('https://use.typekit.net/af/fe9871/000000000000000000013739/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/fe9871/000000000000000000013739/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/fe9871/000000000000000000013739/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Amazon_Ember_Rg';
  src: url('fonts/Amazon_Ember_Rg.ttf') format('truetype');
}

@font-face {
  font-family: 'Amazon_Ember_Lt';
  src: url('fonts/Amazon_Ember_Lt.ttf') format('truetype');
}

/* Akzidenz Grotesk Condensed Bold */
@font-face {
  font-family: 'akzidenz-grotesk-condensed';
  src: url('https://use.typekit.net/af/2607ed/000000000000000000013740/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/2607ed/000000000000000000013740/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/2607ed/000000000000000000013740/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Apotek Wide';
  src: url('https://staging.lcdigital.io/static/media/ApotekWide-Regular.woff2')
      format('woff2'),
    url('https://staging.lcdigital.io/static/media/ApotekWide-Regular.woff')
      format('woff'),
    url('https://staging.lcdigital.io/static/media/ApotekWide-Regular.ttf')
      format('truetype');
}

/* Welcome to Earth - Disney event uses Aviano Future */

@font-face {
  font-family: 'Aviano-Future-Regular';
  src: url('https://staging.lcdigital.io/static/media/Aviano-Future-Regular.woff') format('woff'),
  url('https://staging.lcdigital.io/static/media/Aviano-Future-Regular.woff2') format('woff2'),
  url('https://staging.lcdigital.io/static/media/Aviano-Future-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Aviano-Future-Bold';
  src: url('https://staging.lcdigital.io/static/media/Aviano-Future-Bold.woff') format('woff'),
  url('https://staging.lcdigital.io/static/media/Aviano-Future-Bold.woff2') format('woff2'),
  url('https://staging.lcdigital.io/static/media/Aviano-Future-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Conneqt-Regular';
  src: url('https://staging.lcdigital.io/static/media/Conneqt-Regular.ttf') format('truetype');
}

/* Roboto */
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: auto;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root,
.main {
  height: 100%;
  height: calc(var(--vh, 1vh) * 100);
}
